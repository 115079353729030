<template>
  <div class="floating-navigation">
    <v-btn
      value="home"
      to="/"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_home')"
    >
      <IconHome />
    </v-btn>

    <v-btn
      value="favorites"
      to="/wishlist"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_wishlist')"
    >
      <IconHeart />
    </v-btn>

    <v-btn
      class="cart"
      value="cart"
      to="/cart"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_bag')"
    >
      <div>
        <div
          v-show="$store.getters['cart/hasProducts']"
          class="cart__badge"
        ></div>
        <IconCart class="cart__icon" />
      </div>
    </v-btn>

    <v-btn
      value="brands"
      to="/brands"
      nuxt
      variant="text"
      icon
      @click="$gtmClickPush('footer_brand')"
      ><IconSearch class="search__icon"
    /></v-btn>
  </div>
</template>

<script lang="ts">
import IconCart from '~/assets/images/icon/icon-cart.svg?component'
import IconHeart from '~/assets/images/icon/icon-heart-on.svg?component'
import IconHome from '~/assets/images/icon/icon-home.svg?component'
import IconSearch from '~/assets/images/icon/icon-search.svg?component'

type DataType = {
  showStoreMapPopup: boolean
}

export default defineComponent({
  components: {
    IconHome,
    IconHeart,
    IconCart,
    IconSearch,
  },

  data(): DataType {
    return {
      showStoreMapPopup: false,
    }
  },
})
</script>

<style lang="sass" scoped>
.floating-navigation
  width: 300px
  height: 60px
  background-color: $main-w-color
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1), inset -10px 10px 20px #EEEEEE
  border-radius: 40px
  margin: auto
  right: 0
  bottom: 19px
  left: 0
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 40px

  // キーボードが表示されたときにはFloatingNavigationを非表示にする
  @media screen and (max-height: 450px)
    display: none

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) ::v-deep .v-btn__content
  opacity: 1 !important
  path
    fill: #333
.v-btn--icon.v-btn--active ::v-deep
  path
    fill: #666666

.cart
  position: relative
  top: 2px

.cart__icon
  width: 19px

.cart__badge
  width: 9px
  height: 9px
  border: solid 1px #fff
  border-radius: 50%
  background-color: #DD807F
  position: absolute
  right: 2px
  top: -3px

.search__icon
  width: 22px
  height: 22px
</style>
